import * as React from 'react'
import Head from 'next/head'

import { TrustPilotProps } from '@thg-commerce/enterprise-core/src/ConfigurationLoader/types'

export type TrustBoxProps = Omit<TrustPilotProps, 'theme'> & {
  trustBoxTheme?: string
  dataStars?: string
  reviewLanguageCode?: string
  className?: string
}

declare global {
  interface Window {
    Trustpilot: {
      loadFromElement: (
        elementToLoadFrom: HTMLDivElement,
        condition: boolean,
      ) => void
    }
  }
}

// reference: https://support.trustpilot.com/hc/en-us/articles/115011421468--Add-a-TrustBox-to-a-single-page-application
export const TrustBox = (props: TrustBoxProps) => {
  const trustBoxRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    /* 
      This is not called under normal operations - window.trustPilot
      is not defined when this condition is evaluated, the script
      will load the box automatically. 
      
      This block of code is still included based on the TrustPilot 
      recommendation however, as web applications that route in a none-standard 
      way may not trigger the script load behaviour, and so to be safe the 
      loadFromElement is called on mount. 
    */
    if (window.Trustpilot && trustBoxRef.current) {
      window.Trustpilot.loadFromElement(trustBoxRef.current, true)
    }
  }, [trustBoxRef.current])

  return (
    <React.Fragment>
      <Head>
        <script
          type=""
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
          key="trust-box-script"
        />
      </Head>
      <div
        style={{ display: `flex`, justifyContent: `center` }}
        ref={trustBoxRef}
        className={`trustpilot-widget ${props.className || ''}`}
        data-locale={props.locale}
        data-template-id={props.templateId}
        data-businessunit-id={props.businessId}
        data-style-height={props.height}
        data-style-width={props.width}
        data-theme={props.trustBoxTheme || ''}
        data-stars={props.dataStars}
        data-review-languages={props.reviewLanguageCode}
      >
        <a href={props.link} target="_blank" rel="noopener">
          Trustpilot
        </a>
      </div>
    </React.Fragment>
  )
}
